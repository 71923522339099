










































































































































































































































































































































import { Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import GlobalInputSearchComponent from '@/components/GlobalInputSearchComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import UnauthenticatedHeaderView from '@/views/UnauthenticatedHeaderView.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import MyProfileMenuComponent from '@/components/MyProfileMenuComponent.vue';
import NavbarComponent from '@/components/navbar/NavbarComponent.vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityUser from '@/models/graphql/CommunityUser';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import useTestDataAttribute from '@/utils/TestDataAttribute';

const localStorageCartStore = namespace('LocalStorageCartStore');
const chatStore = namespace('ChatDispatcherStore/ChatStore');
const authenticationStore = namespace('AuthenticationStore');
const notificationStore = namespace('NotificationStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    FontAwesomeComponent,
    InputSearchComponent,
    LocaleSwitcher,
    NavbarComponent,
    ButtonIconComponent,
    UnauthenticatedHeaderView,
    GlobalInputSearchComponent,
    AvatarSoloWidget,
    MyProfileMenuComponent,
  },
  data(): object {
    return {
      fixed: false,
      isMenuOpen: false,
    };
  },
})
export default class MainHeader extends UnauthenticatedHeaderView {
  @authenticationStore.Getter
  private isAuthenticated!: boolean;

  @chatStore.Getter('isClosed')
  private isMessageBoxClose!: boolean;

  @chatStore.Mutation
  private toggleChat!: (close?: boolean) => void;

  @notificationStore.Action
  private closeNotificationPanel!: (close?: boolean) => void;

  @notificationStore.State
  private unreadNotifications!: number;

  @chatStore.Getter
  private unReadCount!: number;

  @notificationStore.Getter('isClosed')
  private isNotificationClosed!: number;

  @localStorageCartStore.Getter
  private numberOfItemInCart!: number;

  @Getter
  private authUser!: CommunityUser;

  private companyId: string | null = null;

  private get isChatPanelFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE).enabled
        && (this.featureByKey(FeatureKeys.COMMUNITY_CHAT_PANEL)
            && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_PANEL).enabled);
  }

  private get showMenuItems(): boolean | undefined {
    return (
      (this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
            && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE).enabled)
        || (this.featureByKey(FeatureKeys.NOTIFICATIONS)
            && this.featureByKey(FeatureKeys.NOTIFICATIONS).enabled)
        || (this.featureByKey(FeatureKeys.GLOBAL_SEARCH)
            && this.featureByKey(FeatureKeys.GLOBAL_SEARCH).enabled)
    );
  }

  created(): void {
    this.$root.$refs.mainHeader = this;
    window.addEventListener('scroll', this.handleScroll);
    this.companyId = this.$route.params.companyId || null;
  }

  destroyed(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(): void {
    this.$data.fixed = window.scrollY >= 2
        && this.$route.name !== 'company-tab-profile'
        && this.$route.name !== 'company-tab-team'
        && this.$route.name !== 'company-tab-products'
        && this.$route.name !== 'company-tab-promotions'
        && this.$route.name !== 'company-tab-documents';
  }

  onAvatarClick(): void {
    this.$data.isMenuOpen = !this.$data.isMenuOpen;
    if (this.$refs['navbar-component-mobile']) {
      (this.$refs['navbar-component-mobile'] as NavbarComponent).toggleHamburgerMenu = false;
    }
    if (!this.isDesktop) {
      this.onToggleProfileMenu(true);
    }
  }

  private toggleNotificationsModal(): void {
    if (!this.isDesktop) {
      this.toggleChat(true);
    }
    this.closeNotificationPanel();
  }

  private onToggleMessageBox(): void {
    this.closeNotificationPanel(true);
    this.toggleChat();
  }

  private onToggleProfileMenu(open: boolean): void {
    this.$data.isMenuOpen = open;
    const app = document.getElementsByTagName('body');
    if (app) {
      if (open) {
        app[0].style.overflow = 'hidden';
      } else {
        app[0].style.overflow = 'visible';
      }
    }
  }
}
